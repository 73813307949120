
import "@/assets/styles.css";
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    ResponseDataChart as responseChart,
    Chart,
    ChartError,
    FilterFormDefinitive,
    SettingsFormInterface,
    ResponseDataString as rds
} from "@/interfaces";
import * as types from "@/store/types";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";
import { Eoption, noData } from "@/utils";
@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        Echart: () => import("@/components/charts/eChart.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        }),
        getColor(Nombre) {
            if (Nombre == "Total") return "light-grey";
            else return "white";
        }
    }
})
export default class Energy extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    filterFormPanelShow: boolean | null = false;
    settingsModalShow: boolean | null = false;
    alturaCW = 500;
    alturaPie = 400;
    initEchart = Eoption;
    tab: any = 0;
    data: any = {
        "2020": { general: noData, submedidas: noData, columnas: [], filas: [] }
    };

    mounted() {
        this.fetchData();
    }
    /**
     * Methods
     */
    async fetchData() {
        this.setProgressBar(true);
        try {
            const consumRes = await this.$api.consumosISO<responseChart>();
            if (consumRes.ok) {
                this.data = consumRes.data;
            }
        } catch (error) {
            this.data = {
                "2020": {
                    general: noData,
                    submedidas: noData,
                    columnas: [],
                    filas: []
                }
            };
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
        this.setProgressBar(false);
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    getEchart(option) {
        if (option == 0) {
            return 0;
        }
        if (option.dataset) {
            if (option.dataset.source) {
                if (option.dataset.source.length > 0) {
                    return { ...Eoption, ...option };
                }
            }
        }
        return noData;
    }
}
